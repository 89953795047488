import React from "react"
import { graphql, PageProps } from "gatsby"
import SEO from "../components/seo"
import { Query } from "../../types/graphql-types"
import Layout from "../components/layout"
import PageTitle from "../components/utils/PageTitle"
import "../components/Value/style.css"
import { ja_description } from "../components/const"

const Value: React.FC<PageProps<Query>> = ({ data, location }) => {
  const valueData = data.microcmsValue
  return (
    <Layout selectPageName="Value" location={location}>
      <SEO title="Value" description={ja_description} lang={"ja"} />
      <div>
        <div>
          <p>
            {valueData?.title && (
              <PageTitle title={valueData.title} key={valueData.id} />
            )}
          </p>
        </div>
        <div className="policy-wrapper">
          {valueData?.policy &&
            valueData?.policy.map(item => {
              return (
                <div className={"policy-item-wrapper"} key={item?.fieldId}>
                  <div className={"policy-title"}>
                    <p>{item?.policyTitle}</p>
                  </div>
                  <div className={"policy-body"}>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${item?.policyBody}`
                        }}
                      />
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}
export default Value

export const ValueIndexQuery = graphql`
  query ValueIndexQuery {
    microcmsValue {
      title
      policy {
        fieldId
        policyTitle
        policyBody
      }
    }
  }
`
